<template>
  <TagbyActionCard>
    <template #left>
      <UpdateComptButton />
      <ProgressedComptButton />
      <GetLikeCommentButton />
    </template>

    <template #right>
      <CreatePostingButton />
      <AddScreenshotJobButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
} from 'bootstrap-vue'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import UpdateComptButton from './UpdateComptButton.vue'
import CreatePostingButton from './CreatePostingButton.vue'
import ProgressedComptButton from './ProgressedComptButton.vue'
import AddScreenshotJobButton from './AddScreenshotJobButton.vue'
import GetLikeCommentButton from './GetLikeCommentButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,

    UpdateComptButton,
    CreatePostingButton,
    ProgressedComptButton,
    AddScreenshotJobButton,
    GetLikeCommentButton,
  },
  setup() {

  },
})
</script>
