<template>
  <TagbyActionButton
    variant="outline-secondary"
    block
    :isValid="isValid"
    text="스크린샷 잡 추가"
    @click="turnOn"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useAddScreenshotJobButton, useAddScreenshotJobSidebar } from '../viewModel'
import TagbyActionButton from '@/components/TagbyActionButton.vue'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const { isValid } = useAddScreenshotJobButton()
    const { turnOn } = useAddScreenshotJobSidebar()
    return {
      isValid,
      turnOn,
    }
  },
})
</script>
