import axios from '@axios'

const STORE_MODULE_NAME = 'compt'

const actions = {
  addScreenshotJob(ctx, { campaignIdx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/${campaignIdx}/screenshot-job/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchScreenshotJobList(ctx, { campaignIdx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/${campaignIdx}/screenshot-job/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  pullUp(ctx, { campaignIdx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/${campaignIdx}/pull-up/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  cancelReward(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/cancel-reward/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getBlogLikeComment(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/get-blog-like-comment/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
