<template>
  <TagbyDangerModal
    :visible="visible"
    title="캠페인 진행완료"
    content="해당 캠페인을 진행완료처리 하시겠습니까"
    @ok="progressedCompt"
    @hidden="turnOff"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useProgressedComptModal } from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      visible,
      turnOff,
      progressedCompt,
    } = useProgressedComptModal()

    return {
      visible,
      turnOff,
      progressedCompt,
    }
  },
})
</script>
