<template>
  <TagbySidebar
    title="스크린샷 잡 추가"
    :visible="visible"
    width="30%"
    @hidden="turnOff"
  >
    <template #content>
      <BRow>
        <BCol>
          <BFormGroup label="keywords">
            <BFormTags v-model="keywords" />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="interval">
            <BFormInput
              v-model="interval"
              type="number"
              number
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="start_at">
            <TagbyDatetimeInputV2
              v-model="startAt"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="end_at">
            <TagbyDatetimeInputV2
              v-model="endAt"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="posting_count">
            <BFormInput
              :value="postingCount"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="max_screenshot_ranking">
            <BFormInput
              v-model="maxScreenshotRanking"
              type="number"
              number
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="max_ranking">
            <BFormInput
              v-model="maxRanking"
              type="number"
              number
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <TagbySidebarButtons
        :isSaving="isSaving"
        :isValid="isValid"
        @ok="addScreenshotJob"
        @cancel="turnOff"
      />
    </template>
  </TagbySidebar>

</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import TagbySidebarButtons from '@/components/TagbySidebarButtons.vue'
import { useAddScreenshotJobSidebar } from '../viewModel'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTags,
    TagbySidebar,
    TagbySidebarButtons,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useAddScreenshotJobSidebar(),
    }
  },
})
</script>
