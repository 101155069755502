<template>
  <TagbyDangerModal
    :visible="visible"
    title="포스팅 삭제"
    content="해당 포스팅을 삭제처리 하시겠습니까"
    @ok="deletePosting"
    @hidden="turnOff"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useDeletePostingModal } from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      visible,
      turnOff,
      deletePosting,
    } = useDeletePostingModal()

    return {
      visible,
      turnOff,
      deletePosting,
    }
  },
})
</script>
