import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useStore from '@/utils/store'
import useToast from '@/utils/toast'
import hiddenComptStoreModule from '@/store/tagby/hiddenCompt'
import comptStoreModule from '@/store/tagby/compt'
import resolveValue from '@/utils/resolveValue'
import { code } from './components/code'

const hasLoadError = ref(false)
const compt = ref({})
const postings = ref([])
const tabStage = ref()
const timelines = ref([])

export function useTab() {
  const setTabStage = tab => {
    tabStage.value = tab
    router.replace({
      query: {
        tab: tabStage.value,
      },
    }).catch(() => { })
  }
  return {
    tabStage,
    setTabStage,
  }
}

export function useInitialize() {
  const {
    setTabStage,
  } = useTab()
  const initialize = () => {
    if (!store.hasModule(hiddenComptStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(hiddenComptStoreModule.STORE_MODULE_NAME, hiddenComptStoreModule)
    }
    if (!store.hasModule(comptStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(comptStoreModule.STORE_MODULE_NAME, comptStoreModule)
    }
    tabStage.value = router.history.current.query.tab
    if (!tabStage.value) {
      setTabStage('campaign')
    }
    onUnmounted(() => {
      if (store.hasModule(hiddenComptStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(hiddenComptStoreModule.STORE_MODULE_NAME)
      }
      if (store.hasModule(comptStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(comptStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
    hasLoadError,
  }
}

export function useTimelines() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchTimelines = () => {
    dispatch('fetchTimelines', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    }).finally(() => {

    })
  }
  return {
    timelines,
    fetchTimelines,
  }
}

export function useComptInfo() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const fetchHiddenCompt = () => {
    dispatch('fetchHiddenCompt', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      compt.value = response.data.data
    }).catch(() => {
      hasLoadError.value = true
    }).finally(() => {

    })
  }
  return {
    compt,
    fetchHiddenCompt,
  }
}

export function useUpdateComptButton() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchHiddenCompt } = useComptInfo()
  const { fetchTimelines } = useTimelines()
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(compt.value.title))
  const updateHiddenCompt = () => {
    isSaving.value = true
    dispatch('updateHiddenCompt', {
      idx: router.currentRoute.params.idx,
      title: compt.value.title,
    }).then(() => {
      fetchHiddenCompt()
      fetchTimelines()
      makeToast('primary', '캠페인을 저장하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '캠페인을 저장하는데 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }
  return {
    isSaving,
    isValid,
    updateHiddenCompt,
  }
}

export function usePostingTable() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchPostingList = () => {
    dispatch('fetchPostingList', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      postings.value = response.data.data
    }).catch(() => {
      makeToast('danger', '포스팅 목록을 불러오는데 실패했습니다')
    }).finally(() => {

    })
  }
  const fields = [
    { key: 'idx' },
    { key: 'sns' },
    { key: 'url' },
    { key: 'state' },
    { key: 'actions' },
  ]
  const filter = ref()
  const filterOn = []
  return {
    postings,
    fetchPostingList,
    fields,
    filter,
    filterOn,
  }
}

export function basicAPIContainer() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchHiddenCompt } = useComptInfo()
  const { fetchPostingList } = usePostingTable()
  const { fetchTimelines } = useTimelines()
  return {
    dispatch,
    makeToast,
    fetchHiddenCompt,
    fetchPostingList,
    fetchTimelines,
  }
}

const visibleCreatePostingSidebar = ref(false)
export function useCreatePostingSidebar() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchPostingList } = usePostingTable()
  const { fetchTimelines } = useTimelines()
  const urlList = ref()
  const turnOn = () => {
    urlList.value = null
    visibleCreatePostingSidebar.value = true
  }
  const turnOff = () => {
    visibleCreatePostingSidebar.value = false
  }
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(urlList.value))
  const createPosting = () => {
    isSaving.value = true
    dispatch('createPosting', {
      idx: router.currentRoute.params.idx,
      url_list: urlList.value,
    }).then(() => {
      fetchPostingList()
      fetchTimelines()
      turnOff()
      makeToast('primary', '포스팅을 생성하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '포스팅을 생성하는데 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visible: visibleCreatePostingSidebar,
    urlList,
    turnOn,
    turnOff,
    isSaving,
    isValid,
    createPosting,
  }
}

const visibleUpdatePostingSidebar = ref(false)
const postingItem = ref()
const urlUpdatePosting = ref()
export function useUpdatePostingSidebar() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchPostingList } = usePostingTable()
  const { fetchTimelines } = useTimelines()
  const turnOn = item => {
    postingItem.value = item
    urlUpdatePosting.value = item.url
    visibleUpdatePostingSidebar.value = true
  }
  const turnOff = () => {
    visibleUpdatePostingSidebar.value = false
  }
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(urlUpdatePosting.value))
  const updatePosting = () => {
    isSaving.value = true
    dispatch('updatePosting', {
      campaignIdx: router.currentRoute.params.idx,
      postingIdx: postingItem.value.idx,
      url: urlUpdatePosting.value,
    }).then(() => {
      fetchPostingList()
      fetchTimelines()
      turnOff()
      makeToast('primary', '포스팅을 수정하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '포스팅을 수정하는데 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visible: visibleUpdatePostingSidebar,
    url: urlUpdatePosting,
    turnOn,
    turnOff,
    isSaving,
    isValid,
    updatePosting,
  }
}

const visibleDeletePostingModal = ref(false)
export function useDeletePostingModal() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchPostingList } = usePostingTable()
  const { fetchTimelines } = useTimelines()

  const turnOn = item => {
    postingItem.value = item
    visibleDeletePostingModal.value = true
  }
  const turnOff = () => {
    visibleDeletePostingModal.value = false
  }
  const deletePosting = () => {
    dispatch('deletePosting', {
      campaignIdx: router.currentRoute.params.idx,
      postingIdx: postingItem.value.idx,
    }).then(() => {
      fetchPostingList()
      fetchTimelines()
      turnOff()
      makeToast('primary', '포스팅을 삭제하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '포스팅을 삭제하는데 실패했습니다')
    }).finally(() => {
    })
  }

  return {
    visible: visibleDeletePostingModal,
    turnOn,
    turnOff,
    deletePosting,
  }
}

export function useProgressedComptButton() {
  const isValid = computed(() => compt.value.step !== 'PROGRESSED')
  return {
    isValid,
  }
}

const visibleProgressedModal = ref(false)
export function useProgressedComptModal() {
  const {
    dispatch,
    makeToast,
    fetchHiddenCompt,
    fetchTimelines,
  } = basicAPIContainer()

  const turnOn = () => {
    visibleProgressedModal.value = true
  }
  const turnOff = () => {
    visibleProgressedModal.value = false
  }

  const progressedCompt = () => {
    dispatch('progressedCompt', {
      idx: router.currentRoute.params.idx,
    }).then(() => {
      fetchHiddenCompt()
      fetchTimelines()
      turnOff()
      makeToast('primary', '캠페인을 진행완료처리하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '캠페인을 진행완료처리하는데 실패했습니다')
    }).finally(() => {
    })
  }

  return {
    visible: visibleProgressedModal,
    turnOn,
    turnOff,
    progressedCompt,
  }
}

const screenshotJobList = ref([])
export function useScreenshotJobTableCard() {
  const fields = [
    { key: 'idx' },
    { key: 'keywords' },
  ]
  const { dispatch } = useStore(comptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchScreenshotJobList = () => {
    dispatch('fetchScreenshotJobList', {
      campaignIdx: router.currentRoute.params.idx,
    }).then(response => {
      screenshotJobList.value = response.data.data
    }).catch(() => {
      makeToast('danger', '스크린샷 잡을 불러오는데 실패했습니다')
    }).finally(() => {
    })
  }
  return {
    screenshotJobList,
    fields,
    fetchScreenshotJobList,
  }
}

export function useAddScreenshotJobButton() {
  const isValid = computed(() => {
    const tempPostings = postings.value ?? []
    const firstComfirmedPostng = tempPostings.find(p => p.state === 'CONFIRMED')
    return firstComfirmedPostng != null
  })
  return {
    isValid,
  }
}

const visibleAddScreenshotJobSidebar = ref(false)
export function useAddScreenshotJobSidebar() {
  const turnOn = () => {
    visibleAddScreenshotJobSidebar.value = true
  }
  const turnOff = () => {
    visibleAddScreenshotJobSidebar.value = false
  }
  const { fetchTimelines } = useTimelines()
  const { fetchScreenshotJobList } = useScreenshotJobTableCard()
  const keywords = ref()
  const interval = ref()
  const startAt = ref()
  const endAt = ref()
  const postingCount = computed(() => {
    const tempPostings = postings.value ?? []
    const comfirmedPostngs = tempPostings.filter(p => p.state === 'CONFIRMED')
    return comfirmedPostngs.length
  })
  const maxScreenshotRanking = ref(10)
  const maxRanking = ref(50)
  const isSaving = ref(false)
  const isValid = computed(() => keywords.value != null && interval.value != null
    && maxScreenshotRanking.value != null && maxRanking.value != null && startAt.value && endAt.value)
  const { makeToast } = useToast()
  const { dispatch } = useStore(comptStoreModule.STORE_MODULE_NAME)
  const { resolveUtcDateTime } = resolveValue()
  const addScreenshotJob = () => {
    const params = {
      campaignIdx: router.currentRoute.params.idx,
      keywords: keywords.value,
      interval: interval.value,
      start_at: resolveUtcDateTime(startAt.value),
      end_at: resolveUtcDateTime(endAt.value),
      posting_count: postingCount.value,
      max_screenshot_ranking: maxScreenshotRanking.value,
      max_ranking: maxRanking.value,
    }

    isSaving.value = true
    dispatch('addScreenshotJob', params).then(() => {
      makeToast('primary', '스크린샷 추가에 성공했습니다')
      fetchTimelines()
      fetchScreenshotJobList()
      turnOff()
    }).catch(() => {
      makeToast('danger', '스크린샷 추가에 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }
  return {
    visible: visibleAddScreenshotJobSidebar,
    turnOn,
    turnOff,
    keywords,
    interval,
    startAt,
    endAt,
    postingCount,
    maxScreenshotRanking,
    maxRanking,
    addScreenshotJob,
    isSaving,
    isValid,
  }
}

const visibleGetLikeModal = ref(false)
export function useGetLikeComment() {
  const isProgressing = ref(false)
  const validPostingUrl = computed(() => postings.value.filter(p => Boolean(p.url)).map(p => p.url))
  const snsChannel = computed(() => (validPostingUrl.value?.[0]?.includes('instagram') ? 'INSTAGRAM' : 'BLOG'))
  const downloadBlogLikeComment = () => {
    function downloadCsv(data) {
      const title = 'url,like,comment\n'
      const mappedData = data.map(row => `${row.url},${row.like},${row.comment}`)
      const joinedData = mappedData.join('\n')
      const csvData = title.concat(joinedData)
      const blob = new Blob([csvData], { type: 'text/csv' })
      const linkEl = document.createElement('a')
      linkEl.href = window.URL.createObjectURL(blob)
      linkEl.download = 'like_comment.csv'
      linkEl.click()
    }
    const SNS_MODULE_URL = process.env.VUE_APP_SNS_MODULE_URL
    function getToken() {
      const TAGBY_SNS_USERNAME = process.env.VUE_APP_TAGBY_SNS_USERNAME
      const TAGBY_SNS_PASSWORD = process.env.VUE_APP_TAGBY_SNS_PASSWORD
      const USER_DATA = {
        username: TAGBY_SNS_USERNAME,
        password: TAGBY_SNS_PASSWORD,
      }
      return fetch(`${SNS_MODULE_URL}/login`, {
        method: 'post',
        body: new URLSearchParams(USER_DATA),
      }).then(response => response.json())
    }
    function getBlogLikeComment(urls) {
      return getToken().then(tokenResJson => new Promise(resolve => {
        const { token } = tokenResJson
        const headers = {
          Authorization: `Bearer ${token}`,
        }
        resolve(headers)
      }).then(headers => Promise.all(urls.map(url => fetch(`${SNS_MODULE_URL}/api/v3/naver/get_post_info`, {
        method: 'post',
        headers,
        body: JSON.stringify({ url }),
      }).then(response => response.json())
        .then(resJson => ({
          url,
          like: resJson.data?.like_count ?? 'Error',
          comment: resJson.data?.comment_count ?? 'Error',
        }))
        .catch(() => ({
          url,
          like: 'Error',
          comment: 'Error',
        }))))))
    }
    isProgressing.value = true
    getBlogLikeComment(validPostingUrl.value)
      .then(data => {
        downloadCsv(data)
      }).finally(() => {
        isProgressing.value = false
      })
  }
  const turnOnModal = () => {
    visibleGetLikeModal.value = true
  }
  const clickButton = () => {
    if (snsChannel.value === 'BLOG') {
      downloadBlogLikeComment()
    } else if (snsChannel.value === 'INSTAGRAM') {
      turnOnModal()
    }
  }
  const turnOffModal = () => {
    visibleGetLikeModal.value = false
  }
  const appendedCode = computed(() => {
    const re = new RegExp(/(?<=instagram.com\/p\/)\w+(?=\/?)/)
    const targetShortcodeList = validPostingUrl.value.map(url => re.exec(url)?.[0])
    return `t(${JSON.stringify(targetShortcodeList)});`
  })

  const copiedCode = computed(() => `${code}${appendedCode.value}`)

  const { makeToast } = useToast()
  const clickCopyCode = () => {
    window.navigator.clipboard.writeText(copiedCode.value)
    makeToast('primary', '코드가 복사됐습니다')
  }

  return {
    isProgressing,
    snsChannel,
    visibleGetLikeModal,
    clickButton,
    turnOffModal,
    appendedCode,
    copiedCode,
    clickCopyCode,
  }
}

export function others() {

}
