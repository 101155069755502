<template>
  <TagbySidebar
    :visible="visible"
    title="포스팅 생성"
    @hidden="turnOff"
  >
    <template #content>
      <BRow>
        <BCol>
          <BFormGroup label="URL">
            <BFormTextarea
              v-model="urlList"
              rows="5"
              placeholder="URL목록을 라인(Enter)으로 구분해서 입력해주세요"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <TagbySidebarButtons
        :isSaving="isSaving"
        :isValid="isValid"
        @ok="createPosting"
        @cancel="turnOff"
      />
    </template>
  </TagbySidebar>

</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import TagbySidebarButtons from '@/components/TagbySidebarButtons.vue'
import {
  useCreatePostingSidebar,
} from '../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    TagbySidebar,
    TagbySidebarButtons,
  },
  setup() {
    const {
      urlList,
      isSaving,
      isValid,
      visible,
      turnOff,
      createPosting,
    } = useCreatePostingSidebar()

    return {
      urlList,
      isSaving,
      isValid,
      visible,
      turnOff,
      createPosting,
    }
  },
})
</script>
