<template>
  <BCard title="캠페인 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="title">
          <BFormInput v-model="compt.title" />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="state">
          <BFormInput
            :value="compt.state"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="step">
          <BFormInput
            :value="compt.step"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { useComptInfo } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const {
      compt,
    } = useComptInfo()
    return {
      compt,
    }
  },
})
</script>
