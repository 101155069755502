<template>
  <BCard>
    <BRow class="mb-1">
      <BCol cols="3">
        <BInputGroup size="sm">
          <BFormInput
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
        </BInputGroup>
      </BCol>
      <!-- <BCol cols="9">
        <BFormRadioGroup
          v-model="selected"
          :options="options"
        />
      </BCol> -->
    </BRow>
    <BTable
      responsive
      small
      :fields="fields"
      :items="postings"
      :filter="filter"
      :filter-included-fields="filterOn"
      style="overflow-x: visible;"
    >
      <template #cell(url)="data">
        <BLink
          :href="data.value"
          target="_blank"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(actions)="data">
        <BDropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <FeatherIcon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <BDropdownItem
            @click="turnOnUpdatePostingSidebar(data.item)"
          >
            <FeatherIcon icon="EditIcon" />
            <span class="align-middle ml-50">URL 수정</span>
          </BDropdownItem>

          <BDropdownItem @click="turnOnDeletePostingModal(data.item)">
            <FeatherIcon icon="XSquareIcon" />
            <span class="align-middle ml-50">삭제</span>
          </BDropdownItem>
        </BDropdown>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BLink,
  BInputGroup,
  BFormInput,
  BFormRadioGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import {
  usePostingTable,
  useUpdatePostingSidebar,
  useDeletePostingModal,
} from '../viewModel'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,
    TagbySnsColumn,
  },
  setup() {
    const {
      postings,
      fetchPostingList,
      fields,
      filter,
      filterOn,
    } = usePostingTable()

    fetchPostingList()

    const {
      turnOn: turnOnUpdatePostingSidebar,
    } = useUpdatePostingSidebar()

    const {
      turnOn: turnOnDeletePostingModal,
    } = useDeletePostingModal()

    return {
      postings,
      fields,
      filter,
      filterOn,

      turnOnUpdatePostingSidebar,
      turnOnDeletePostingModal,
    }
  },
})
</script>
