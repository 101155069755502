export const originalCode = String.raw`
async function getQuery(queryId, shortcode) {
  const url = 'https://www.instagram.com/graphql/query';
  const params = new URLSearchParams({
      query_id: queryId,
      variables: JSON.stringify({
          shortcode,
          include_reel: false,
          first: 0,
      })
  });
  const res = await fetch(url + '?' + params);
  return await res.json();
}

async function makePostingData(shortcodeList) {
return await Promise.all(shortcodeList.map(async shortcode => {
  const likeObj = await getQuery('17864450716183058', shortcode);
  const commentObj = await getQuery('17852405266163336', shortcode);

  return {
    url: 'https://www.instagram.com/p/' + shortcode + '/',
    like: likeObj?.data?.shortcode_media?.edge_liked_by?.count ?? 'Error',
    comment: commentObj?.data?.shortcode_media?.edge_media_to_comment?.count ?? 'Error',
  }
}))
}

function downloadCsv(data) {
const title = 'url,like,comment\n';
const mappedData = data.map(row => row.url + ',' + row.like + ',' + row.comment);
const joinedData = mappedData.join('\n');
  const csvData = title.concat(joinedData);

  const blob = new Blob([csvData], { type: 'text/csv' });
  const linkEl = document.createElement('a');
  linkEl.href = window.URL.createObjectURL(blob);
  linkEl.download = 'like_comment.csv';
  linkEl.click();
}

async function mainWork(shortcodeList) {
  const data = await makePostingData(shortcodeList);
  downloadCsv(data);
}
`
export const code = String.raw`async function c(t,n){const e="https://www.instagram.com/graphql/query";const c=new URLSearchParams({query_id:t,variables:JSON.stringify({shortcode:n,include_reel:false,first:0})});const o=await fetch(e+"?"+c);return await o.json()}async function e(t){return await Promise.all(t.map(async t=>{const n=await c("17864450716183058",t);const e=await c("17852405266163336",t);return{url:"https://www.instagram.com/p/"+t+"/",like:n?.data?.shortcode_media?.edge_liked_by?.count??"Error",comment:e?.data?.shortcode_media?.edge_media_to_comment?.count??"Error"}}))}function o(t){const n="url,like,comment\n";const e=t.map(t=>t.url+","+t.like+","+t.comment);const c=e.join("\n");const o=n.concat(c);const a=new Blob([o],{type:"text/csv"});const r=document.createElement("a");r.href=window.URL.createObjectURL(a);r.download="like_comment.csv";r.click()}async function t(t){const n=await e(t);o(n)}`
