<template>
  <BButton
    variant="outline-danger"
    block
    :disabled="!isValid"
    @click="turnOn"
  >
    캠페인 진행완료
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
} from 'bootstrap-vue'
import {
  useProgressedComptButton,
  useProgressedComptModal,
} from '../viewModel'

export default defineComponent({
  components: {
    BButton,
  },
  setup() {
    const {
      isValid,
    } = useProgressedComptButton()
    const {
      turnOn,
    } = useProgressedComptModal()

    return {
      isValid,
      turnOn,
    }
  },
})
</script>
