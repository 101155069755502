<template>
  <TagbyActionButton
    variant="outline-secondary"
    text="좋아요/댓글 수 확인"
    :isSaving="isProgressing"
    @click="clickButton"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import { useGetLikeComment } from '../viewModel'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      clickButton,
      isProgressing,
    } = useGetLikeComment()
    return {
      isProgressing,
      clickButton,
    }
  },
})
</script>
