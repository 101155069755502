<template>
  <BButton
    variant="outline-secondary"
    block
    @click="turnOn"
  >
    포스팅 추가
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
} from 'bootstrap-vue'
import { useCreatePostingSidebar } from '../viewModel'

export default defineComponent({
  components: {
    BButton,
  },
  setup() {
    const {
      turnOn,
    } = useCreatePostingSidebar()

    return {
      turnOn,
    }
  },
})
</script>
