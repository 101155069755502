import axios from '@axios'

const STORE_MODULE_NAME = 'hiddenCompt'

const actions = {
  createHiddenCompt(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchHiddenCompt(ctc, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  updateHiddenCompt(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctc, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/timelines/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchPostingList(ctc, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/posting/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  createPosting(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/posting/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  updatePosting(ctx, { campaignIdx, postingIdx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${campaignIdx}/posting/${postingIdx}/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  deletePosting(ctx, { campaignIdx, postingIdx }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${campaignIdx}/posting/${postingIdx}/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  progressedCompt(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/hidden-compt/${idx}/progressed/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
