<template>
  <TagbySidebar
    :visible="visible"
    title="포스팅 수정"
    @hidden="turnOff"
  >
    <template #content>
      <BRow>
        <BCol>
          <BFormGroup label="URL">
            <BFormInput v-model="url" />
          </BFormGroup>
        </BCol>
      </BRow>
      <TagbySidebarButtons
        :isSaving="isSaving"
        :isValid="isValid"
        @ok="updatePosting"
        @cancel="turnOff"
      />
    </template>
  </TagbySidebar>

</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import TagbySidebarButtons from '@/components/TagbySidebarButtons.vue'
import {
  useUpdatePostingSidebar,
} from '../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbySidebar,
    TagbySidebarButtons,
  },
  setup() {
    const {
      url,
      isSaving,
      isValid,
      visible,
      turnOff,
      updatePosting,
    } = useUpdatePostingSidebar()

    return {
      url,
      isSaving,
      isValid,
      visible,
      turnOff,
      updatePosting,
    }
  },
})
</script>
