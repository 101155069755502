<template>
  <div>
    <TagbyLoadError
      :hasLoadError="hasLoadError"
      subject="campaign"
      :to="{ name: 'campaign-compt-list' }"
    />
    <BRow>
      <BCol cols="9">
        <BTabs>
          <BTab
            title="캠페인"
            :active="tabStage === 'campaign'"
            @click="setTabStage('campaign')"
          >
            <ComptInfoCard />
          </BTab>
          <BTab
            title="포스팅"
            :active="tabStage === 'posting'"
            @click="setTabStage('posting')"
          >
            <PostingTableCard />
          </BTab>
          <BTab
            title="스크린샷 잡"
            :active="tabStage === 'screenshot-job'"
            @click="setTabStage('screenshot-job')"
          >
            <ScreenshotJobTableCard />
          </BTab>
        </BTabs>
      </BCol>

      <BCol cols="3">
        <ActionCard />
        <TagbyTimeline
          :timelineData="timelines"
        />
      </BCol>
    </BRow>

    <CreatePostingSidebar />
    <UpdatePostingSidebar />
    <DeletePostingModal />
    <ProgressedComptModal />
    <AddScreenshotJobSidebar />
    <GetLikeCommentModal />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTabs,
  BTab,
  BRow,
  BCol,
} from 'bootstrap-vue'
import TagbyLoadError from '@/components/TagbyLoadError.vue'
import {
  useInitialize,
  useTab,
  useComptInfo,
  useTimelines,
} from './viewModel'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import ComptInfoCard from './components/ComptInfoCard.vue'
import PostingTableCard from './components/PostingTableCard.vue'
import ActionCard from './components/ActionCard.vue'
import CreatePostingSidebar from './components/CreatePostingSidebar.vue'
import UpdatePostingSidebar from './components/UpdatePostingSidebar.vue'
import DeletePostingModal from './components/DeletePostingModal.vue'
import ProgressedComptModal from './components/ProgressedComptModal.vue'
import AddScreenshotJobSidebar from './components/AddScreenshotJobSidebar.vue'
import ScreenshotJobTableCard from './components/ScreenshotJobTableCard.vue'
import GetLikeCommentModal from './components/GetLikeCommentModal.vue'

export default defineComponent({
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    TagbyLoadError,
    TagbyTimeline,
    ComptInfoCard,
    PostingTableCard,
    ActionCard,
    CreatePostingSidebar,
    UpdatePostingSidebar,
    DeletePostingModal,
    ProgressedComptModal,
    AddScreenshotJobSidebar,
    ScreenshotJobTableCard,
    GetLikeCommentModal,
  },
  setup() {
    const {
      initialize,
      hasLoadError,
    } = useInitialize()

    initialize()

    const {
      fetchHiddenCompt,
    } = useComptInfo()

    fetchHiddenCompt()

    const {
      timelines,
      fetchTimelines,
    } = useTimelines()

    fetchTimelines()

    const {
      tabStage,
      setTabStage,
    } = useTab()

    return {
      initialize,
      hasLoadError,
      tabStage,
      setTabStage,
      timelines,
    }
  },
})
</script>
